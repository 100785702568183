<template>
  <div>
    <div class='row'>
      <div class='col-lg-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header d-flex justify-content-between align-items-center'>
            <vb-headers-card-header :data="{ title: 'Yayın Takvimi' }" />
            <div>
              <a-button class='btn btn-primary btn-with-addon text-nowrap mr-2' @click='() => showS3RecordsModal = true'>
                <span class='btn-addon d-flex align-items-center justify-content-center' v-if='isLoadingS3List'>
                  <loading-outlined></loading-outlined>
                </span>
                <span class='btn-addon' v-if='!isLoadingS3List'>
                  <i class='btn-addon-icon fe fe-video' />
                </span>
                Yayın Tekrarları
              </a-button>
              <router-link to='/yeni-yayin-ekle' type='button' class='btn btn-primary btn-with-addon text-nowrap'>
              <span class='btn-addon'>
                <i class='btn-addon-icon fe fe-plus-circle' />
              </span>
                Yeni Yayın Ekle
              </router-link>
            </div>
          </div>
          <div class='card-body'>
            <div>
              <div class='table-responsive text-nowrap'>
                <a-table
                  :locale='{emptyText: "Yayın Bulunamadı"}'
                  :columns='columns'
                  :data-source='broadcastProgramItems'
                  :row-key='(record) => record.id'
                  :loading='loading'
                  :pagination='false'
                >
                  <template #id='{record}'>
                    <div>{{ record.id }}</div>
                  </template>
                  <template #personalTrainer='{record}'>
                    <div class='vb__utils__avatar'>
                      <a-image
                        :src='record.personalTrainer.avatar?.avatar4X ?? " "'
                        alt='Antrenör'
                        fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                      />
                    </div>
                    <div class='ml-2'>{{ record.personalTrainer.fullName ?? record.personalTrainer.name + ' ' + record.personalTrainer.surname }}</div>
                  </template>
                  <template #isLive='{record}'>
                    <div :class='record.isLive ? "text-red" : record.isEnded ? "text-success" : "font-italic text-gray-3"'>
                      {{ record.isLive ? 'Canlı' : record.isEnded ? 'Tamamlandı' : 'Başlamadı' }}
                    </div>
                  </template>
                  <template #startsAt='{record}'>
                    <div>{{ moment(record.startsAt).format('LL, HH:mm') }}</div>
                  </template>
                  <template #titleRecord='{record}'>
                    <div>{{ record.title }}</div>
                  </template>
                  <template #caloriesBurnt='{record}'>
                    <div :class='!record.caloriesBurnt ? "font-italic text-gray-3" : ""'>{{ record.caloriesBurnt ?? 'Belirtilmemiş' }}</div>
                  </template>
                  <template #coverPhoto='{ record }'>
                    <div class='vb__utils__avatar'>
                      <a-image
                        :src='record.coverPhoto.coverImage2X'
                        alt='Fotoğraf'
                        fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                      />
                    </div>
                  </template>
                  <template #replay='{record}'>
                    <div>
                      <a-button v-if='record.replayHlsUrl' @click='showReplayModal(record.replayHlsUrl)' class='btn btn-outline-danger'>
                        <i class='fa fa-play' />
                      </a-button>
                    </div>
                  </template>
                  <template #operation='{record}'>
                    <div>
                      <a-popconfirm
                        title='Yayını Silmek İstediğinize Emin Misiniz?(Yayın Canlı İse Sonlandırılacaktır)'
                        ok-text='Evet'
                        cancel-text='Hayır'
                        @confirm='confirmDelete(record.id)'
                      >
                        <a-button class='btn btn-danger mx-2'>
                          <i class='fe fe-trash text white' />
                        </a-button>
                      </a-popconfirm>
                      <a-button
                        @click='() => showEditModal(record.id)'
                        class='btn btn-primary'>
                        <i class='fe fe-settings' />
                      </a-button>
                    </div>
                  </template>
                </a-table>
              </div>
              <div class='card-body'>
                <div class='clearfix'>
                  <a-pagination
                    class='float-right'
                    @change='loadBroadcastProgram'
                    v-model:page-size='pagination.recordsPerPage'
                    :default-page-size='pagination.recordsPerPage'
                    v-model:current='pagination.currentPage'
                    :default-current='pagination.currentPage'
                    :total='pagination.totalRecords'
                    :show-total='total => `Toplam ${total} yayın`'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal v-model:visible='showS3RecordsModal' width='700px' centered title='Canlı Yayın Tekrarları'>
      <div style='max-height: calc(100vh - 400px); width: 100%; overflow-x: auto'>
        <a-table
          :columns='[
            {
              title: "Adres",
              dataIndex: "Key",
              slots: { customRender: "Key" }
            },
            {
              title: "Tarih",
              dataIndex: "LastModified",
              slots: { customRender: "lastModified" },
              width: 200,
            },
            {
              title: "Oynat",
              slots: { customRender: "replayS3" }
            }
          ]'
          :data-source='s3RecordsList'
          :row-key='record => record.Key'
          :pagination='false'
          :loading='isLoadingS3List'
        >
          <template #replayS3='{record}'>
            <div class='d-flex align-items-center w-100'>
              <a-button @click='() => showReplayModal("https://onlinetrainingcomtr.s3.eu-central-1.amazonaws.com/" + record.Key)' class='btn btn-primary mr-2'>
                <i class='fe fe-play' />
              </a-button>
            </div>
          </template>
          <template #lastModified='{text}'>
            <span>{{ moment(text).format('YYYY-MM-DD HH:mm:ss') }}</span>
          </template>
          <template #Key='{record}'>
            <button class='btn text-nowrap overflow-hidden' @click='() => copyURL(`https://onlinetrainingcomtr.s3.eu-central-1.amazonaws.com/${record.Key}`)'
                    style='width: 250px; text-overflow: ellipsis'>
              https://onlinetrainingcomtr.s3.eu-central-1.amazonaws.com/{{ record.Key }}
            </button>
          </template>
        </a-table>
      </div>
      <template #footer>
        <a-button key='back' @click='() => showS3RecordsModal = false'>Kapat</a-button>
      </template>
    </a-modal>

    <a-modal v-model:visible='visibleEditModal' centered title='Yayını Düzenle'>
      <template #footer>
        <a-button key='back' @click='visibleEditModal = false'>Kapat</a-button>
      </template>

      <div class='card-body'>
        <image-resizer
          :loading='loading'
          :on-complete='updateBroadcastCoverPhoto'
          :max-size='988'
          :initial-image='broadcastCover'
          :error-text='"Fotoğraf Ölçüleri 800x500 Piksel ve Katlarında Olmalıdır!"'
        />

        <a-form
          :loading='loading'
          label-align='left'
          layout='vertical'
        >
          <a-form-item label='Yayın Başlığı' name='title'>
            <a-input v-model:value='broadcastTitle' />

            <a-button v-if='showUpdateBroadcastTitle' :loading='loading' @click='updateBroadcastTitle' class='btn btn-success mt-2'>
              Güncelle
            </a-button>
          </a-form-item>

          <a-form-item label='Tekrar Linki' name='title'>
            <div class='d-flex align-items-center'>
              <a-input class='mr-2' v-model:value='broadcastReplayHlsUrl' />
              <a-button @click='() => showS3RecordsModal = true' class='btn btn-success'>
                Seç
              </a-button>
            </div>

            <a-button v-if='showUpdateBroadcastReplayHlsUrl' :loading='loading' @click='updateBroadcastReplayHlsUrl' class='btn btn-success mt-2'>
              Güncelle
            </a-button>
          </a-form-item>

          <a-form-item label='Antrenör' name='broadcastPt'>
            <a-select
              v-model:value='value'
              placeholder='Antrenör Seçin'
              :show-search='true'
              :show-arrow='true'
              :filter-option='false'
              :on-popup-scroll='onScrollTrainersDropdown'
              :not-found-content='fetching ? undefined : null'
              :options='data'
              :loading='fetching'
              @search='loadTrainers'
              @change='updateBroadcastPt'
            >
              <template v-if='fetching' #notFoundContent>
                <a-spin size='small' />
              </template>
            </a-select>

          </a-form-item>

          <a-locale-provider :locale='tr_TR'>
            <a-form-item label='Başlama Tarihi' name='startMoment'>

              <a-date-picker
                v-model:value='broadcastStartTime'
                :disabled-date='disabledStartDate'
                class='w-100'
                show-time
                format='YYYY-MM-DD HH:mm:ss'
                placeholder='Başlangıç Tarihi Ve Saati'
                @openChange='handleStartOpenChange'
              />

              <a-button v-if='showUpdateBroadcastStartTime' :loading='loading' @click='updateBroadcastStartMoment' class='btn btn-success mt-2'>
                Güncelle
              </a-button>
            </a-form-item>

            <a-form-item label='Bitiş Tarihi' name='endMoment'>
              <a-date-picker
                v-model:value='broadcastEndTime'
                :disabled-date='disabledEndDate'
                class='w-100'
                show-time
                format='YYYY-MM-DD HH:mm:ss'
                placeholder='Bitiş Tarihi Ve Saati'
                :open='endOpen'
                @openChange='handleEndOpenChange'
              />
              <a-button v-if='showUpdateBroadcastEndTime' :loading='loading' @click='updateBroadcastEndMoment' class='btn btn-success mt-2'>
                Güncelle
              </a-button>
            </a-form-item>

          </a-locale-provider>
        </a-form>
      </div>

    </a-modal>

    <a-modal v-model:visible='visibleReplayVideo' centered title='Canlı Yayın Tekrarı' :after-close='replayModalClose'>
      <video ref='video' width='100%' height='320' style='width:100%;height:auto' controls></video>
      <template #footer>
        <a-button key='back' @click='handleCancel'>Kapat</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import moment from 'moment'
import { notification } from 'ant-design-vue'
import { BroadcastProgramAdministration, PersonalTrainer } from '../../services/api-service'
import { LoadingOutlined } from '@ant-design/icons-vue'
import Hls from 'hls.js'
import 'moment/locale/tr'
import locale from 'ant-design-vue/es/date-picker/locale/tr_TR'
import tr_TR from 'ant-design-vue/es/locale-provider/tr_TR'
import { debounce } from 'lodash'

const listAllObjects = require('s3-list-all-objects')

moment.locale('tr')

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Fotoğraf',
    dataIndex: 'coverPhoto',
    slots: { customRender: 'coverPhoto' },
  },
  {
    title: 'Yayın Başlığı',
    dataIndex: 'title',
    slots: { customRender: 'titleRecord' },
  },
  {
    title: 'Yayıncı',
    dataIndex: 'personalTrainer',
    class: 'd-flex align-items-center',
    slots: { customRender: 'personalTrainer' },
  },
  {
    title: 'Yayın Durumu',
    dataIndex: 'isLive',
    slots: { customRender: 'isLive' },
  },
  {
    title: 'Yayın Tarihi Ve Saati',
    dataIndex: 'startsAt',
    slots: { customRender: 'startsAt' },
  },
  {
    title: 'Kaç Kalori?',
    class: 'w-100',
    dataIndex: 'caloriesBurnt',
    slots: { customRender: 'caloriesBurnt' },
  },
  {
    title: 'Yayın Tekrarı',
    dataIndex: 'replay',
    class: 'text-center',
    slots: { customRender: 'replay' },
  },
  {
    title: 'Yönet',
    dataIndex: 'operation',
    class: 'text-right',
    slots: { customRender: 'operation' },
  },
]

export default {
  name: 'YayinTakvimi',
  components: {
    LoadingOutlined,
  },
  setup() {
    const video = ref(null)
    return {
      video,
    }
  },
  data: function() {
    const handleCancel = () => {
      this.visibleReplayVideo = false
    }

    const fileList = ref([])


    const showReplayModal = (url) => {
      this.visibleReplayVideo = true

      const hls = new Hls()
      setTimeout(() => {
        hls.loadSource(url)
        hls.attachMedia(this.video)
      }, 1000)
    }

    const replayModalClose = () => {
      this.visibleReplayVideo = false
      this.video.pause()
    }

    const state = reactive({
      data: [],
      value: '',
      trainers: [],
      searchValue: '',
      teacherPagination: { isNextPageAvailable: false, firstIdOfNextPage: 0 },
      fetching: false,
    })

    const loadTrainers = debounce(async value => {
      state.data = []
      state.fetching = true
      state.searchValue = value

      try {
        const { data } = await PersonalTrainer.listPersonalTrainers(undefined, value)
        if (data) {
          state.trainers = data.personalTrainers
          state.data = data.personalTrainers.map((pt) => ({
            value: pt.metadataId,
            label: pt.fullName,
          }))

          state.teacherPagination = data.pagination
        }
      } catch {
      }

      state.fetching = false
    }, 300)

    const loadTrainersNextPage = debounce(async () => {
      if (state.teacherPagination.isNextPageAvailable) {
        state.fetching = true
        try {
          const { data } = await PersonalTrainer.listPersonalTrainers(state.teacherPagination.firstIdOfNextPage, state.searchValue)
          if (data) {
            state.data = [...state.data, ...data.personalTrainers.map((pt) => ({
              value: pt.metadataId,
              label: pt.fullName,
            }))]

            state.teacherPagination = data.pagination
          }
        } catch {
          state.teacherPagination = { isNextPageAvailable: false, firstIdOfNextPage: 0 }
        }
        state.fetching = false
      }
    }, 300)

    const showEditModal = (id) => {
      this.visibleEditModal = true
      this.selectedBroadcast = this.broadcastProgramItems.find(x => x.id === id)

      if (this.selectedBroadcast) {
        this.broadcastTitle = this.selectedBroadcast.title
        this.broadcastCover = this.selectedBroadcast.coverPhoto.coverImage1X
        this.broadcastStartTime = this.moment(this.selectedBroadcast.startsAt)
        this.broadcastEndTime = this.moment(this.selectedBroadcast.endsAt)
        this.broadcastReplayHlsUrl = this.selectedBroadcast.replayHlsUrl
        state.value = this.selectedBroadcast.personalTrainer.fullName ?? `${this.selectedBroadcast.personalTrainer.name} ${this.selectedBroadcast.personalTrainer.surname}`
      }
    }

    const startValue = ref()
    const endValue = ref()
    const endOpen = ref(false)

    const disabledStartDate = startValue => {
      if (!startValue || !endValue.value) {
        return false
      }

      return startValue.valueOf() > endValue.value.valueOf()
    }

    const disabledEndDate = endValue => {
      if (!endValue || !startValue.value) {
        return false
      }

      return startValue.value.valueOf() >= endValue.valueOf()
    }

    const handleStartOpenChange = open => {
      if (!open) {
        endOpen.value = true
      }
    }

    const handleEndOpenChange = open => {
      endOpen.value = open
    }

    tr_TR.DatePicker.lang.ok = 'Tamam'

    return {
      tr_TR,
      locale,
      moment,
      endOpen,
      fileList,
      handleCancel,
      showEditModal,
      loadTrainers,
      loadTrainersNextPage,
      showReplayModal,
      replayModalClose,
      handleEndOpenChange,
      handleStartOpenChange,
      disabledEndDate,
      disabledStartDate,
      visibleEditModal: false,
      pagination: { totalPages: 1, currentPage: 1, totalRecords: 0, recordsPerPage: 1 },
      loading: false,
      visible: false,
      visibleReplayVideo: false,
      columns,
      broadcastProgramItems: [],
      selectedBroadcast: {},
      broadcastReplayHlsUrl: '',
      broadcastTitle: '',
      broadcastCover: '',
      broadcastStartTime: moment(undefined),
      broadcastEndTime: moment(undefined),
      personalTrainerId: null,
      personalTrainerName: '',
      isLoadingS3List: false,
      s3RecordsList: [],
      showS3RecordsModal: false,
      ...toRefs(state),
    }
  },
  computed: {
    showUpdateBroadcastTitle() {
      return this.broadcastTitle !== this.selectedBroadcast.title
    },
    showUpdateBroadcastStartTime() {
      return this.broadcastStartTime !== this.selectedBroadcast.startsAt
    },
    showUpdateBroadcastEndTime() {
      return this.broadcastEndTime !== this.selectedBroadcast.endsAt
    },
    showUpdateBroadcastReplayHlsUrl() {
      return this.broadcastReplayHlsUrl !== this.selectedBroadcast.replayHlsUrl
    },
  },
  created: function() {
    this.moment = moment
  },
  mounted() {
    this.loadBroadcastProgram()
    this.loadTrainers('')
    this.getS3RecordsList()
  },
  methods: {
    async loadBroadcastProgram() {
      this.loading = true
      try {
        const { data } = await BroadcastProgramAdministration.listBroadcastProgramsForAdministration(this.pagination.currentPage)
        this.broadcastProgramItems = data.broadcastPrograms
        this.pagination = data.pagination
      } catch {
      }
      this.loading = false
    },
    async updateBroadcastReplayHlsUrl() {
      try {
        const hls = new Hls()
        hls.loadSource(this.broadcastReplayHlsUrl)

        const onLevelLoaded = async (_, data) => {
          await BroadcastProgramAdministration.updateReplayOfBroadcastProgram({
            replayUrl: this.broadcastReplayHlsUrl,
            replayDurationAsSeconds: Math.ceil(data.details.totalduration),
            broadcastProgramId: this.selectedBroadcast.id,
          })

          this.selectedBroadcast.replayHlsUrl = this.broadcastReplayHlsUrl

          notification.success({
            message: 'İşlem Başarılı!',
            description: 'Yayın Linki Başarıyla Değiştirildi!',
          })
        }

        hls.once(Hls.Events.LEVEL_LOADED, onLevelLoaded)
      } catch (e) {
        notification.error({
          message: 'İşlem Başarısız!',
          description: 'Yayın Linki Değiştirilemedi!',
        })
        console.log(e)
      }
    },
    async updateBroadcastTitle() {
      try {
        await BroadcastProgramAdministration.updateTitleOfBroadcastProgram({
          broadcastProgramId: this.selectedBroadcast.id,
          newTitle: this.broadcastTitle,
        })

        this.selectedBroadcast.title = this.broadcastTitle

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Yayın Başlığı Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updateBroadcastStartMoment() {
      this.loading = true
      try {
        await BroadcastProgramAdministration.updateStartMomentOfBroadcastProgram({
          broadcastProgramId: this.selectedBroadcast.id,
          newStartMoment: this.broadcastStartTime,
        })

        this.selectedBroadcast.startsAt = this.broadcastStartTime

        notification.success({
          message: 'işlem Başarılı!',
          description: 'Yayının Başlama Tarihi Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async updateBroadcastEndMoment() {
      this.loading = true
      try {
        await BroadcastProgramAdministration.updateEndMomentOfBroadcastProgram({
          broadcastProgramId: this.selectedBroadcast.id,
          newEndMoment: this.broadcastEndTime,
        })

        this.selectedBroadcast.endsAt = this.broadcastEndTime

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Yayının Bitiş Tarihi Başarıyla Değiştirildi.',
        })
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async updateBroadcastPt(val) {
      this.loading = true

      try {
        await BroadcastProgramAdministration.updatePersonalTrainerOfBroadcastProgram({
          broadcastProgramId: this.selectedBroadcast.id,
          newPersonalTrainerId: val,
        })

        this.selectedBroadcast.personalTrainer = this.trainers.find(x => x.metadataId === val)

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Antrenör Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log({ e })
      }

      this.loading = false
    },
    async updateBroadcastCoverPhoto(photo) {
      this.loading = true
      try {
        const { data } = await BroadcastProgramAdministration.updateCoverPhotoOfBroadcastProgram({
          broadcastProgramId: this.selectedBroadcast.id,
          newCoverPhoto: photo,
        })

        if (data) {
          this.broadcastCover = data.coverPhoto.coverImage1X
          this.selectedBroadcast.coverPhoto = data.coverPhoto

          notification.success({
            message: 'Resim başarıyla değiştirildi',
          })
        }
      } catch {
      }
      this.loading = false
    },
    async onScrollTrainersDropdown(event) {
      const target = event.target
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.loadTrainersNextPage()
      }
    },
    async confirmDelete(id) {
      this.loading = true

      try {
        await BroadcastProgramAdministration.deleteBroadcastProgram({ broadcastProgramId: id })
        this.broadcastProgramItems = this.broadcastProgramItems.filter(x => x.id !== id)
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Yayın Başarıyla Silindi!',
        })
      } catch (e) {
        console.log(e)
      }

      this.loading = false
    },
    async getS3RecordsList() {
      this.isLoadingS3List = true
      this.s3RecordsList = []
      const s3options = {
        accessKeyId: undefined,
        secretAccessKey: undefined,
      }

      try {
        const { data } = await BroadcastProgramAdministration.getS3AccessKeys()
        s3options.accessKeyId = data.accessKeyId
        s3options.secretAccessKey = data.secretAccessKey
      } catch (e) {
        console.log(e)
      }

      if (s3options.accessKeyId && s3options.secretAccessKey) {
        try {
          const callback = (_, data) => {
            const lastData = data.sort((a, b) => (a.LastModified < b.LastModified) - (a.LastModified > b.LastModified)).filter(d => d.Key.endsWith('.m3u8'))
            this.isLoadingS3List = false
            this.s3RecordsList = lastData
          }
          callback.bind(this)
          listAllObjects({ bucket: 'onlinetrainingcomtr', prefix: 'records/', s3options: s3options }, callback)
        } catch (e) {
          console.log(e)
        }
      } else {
        this.isLoadingS3List = false
      }
    },
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext)
        notification.success({
          message: 'Başarılı!',
          description: 'URL başarıyla kopyalandı!',
        })
      } catch ($e) {
        alert('Adres Kopyalanamadı: ' + mytext)
      }
    },
  },
}
</script>
